import React from 'react'
import { ChatMessageType, SourceType, User } from 'types'
import { Loader } from 'components/leerpleinki/components/loader'
import { Sources } from './sources'
import { ReactComponent as UserLogo } from '../../images/user.svg'
import { ReactComponent as ElasticLogo } from '../../images/elastic_logo.svg'
import RobotAvatar from '../../images/robot-avatar.png'

type ChatMessageProps = Omit<ChatMessageType, 'id'> & {
  onSourceClick: (source: string) => void
  onOpenSidebar: () => void
  user: User
}
export const ChatMessage: React.FC<ChatMessageProps> = ({
  content,
  isHuman,
  sources,
  loading,
  user,
  onSourceClick,
  onOpenSidebar,
}) => {
  const messageIcon = isHuman ? (
    <img src={user.picture} alt="User Avatar" className="w-8 h-8 rounded-full" />
  ) : (
    <img src={RobotAvatar} alt="Robot Avatar" className="w-8 h-8" />
  )

  const handleSourceClick = (sourceName: string) => {
    console.log('ChatMessage - handleSourceClick:', sourceName)
    onOpenSidebar()
    onSourceClick(sourceName)
  }

  return (
    <div className={`flex gap-4 mb-6 ${isHuman ? 'justify-end' : 'justify-start'}`}>
      {!isHuman && <div className="flex-shrink-0 mt-1">{messageIcon}</div>}
      
      <div className={`flex flex-col max-w-[80%] ${isHuman ? 'items-end' : 'items-start'}`}>
        <div
          className={`px-4 py-3 rounded-2xl ${
            isHuman
              ? 'bg-brand-blue text-white rounded-tr-none'
              : 'bg-gray-100 text-gray-900 rounded-tl-none'
          }`}
        >
          <div
            className="whitespace-pre-wrap"
            dangerouslySetInnerHTML={{ __html: content || '' }}
          />
          {loading && <Loader />}
        </div>
        
        {!!sources?.length && (
          <div className="mt-2">
            <Sources 
              sources={sources} 
              onSourceClick={handleSourceClick}
              onOpenSidebar={onOpenSidebar}
              showDisclaimer={true}
            />
          </div>
        )}
      </div>
      
      {isHuman && <div className="flex-shrink-0 mt-1">{messageIcon}</div>}
    </div>
  )
}
