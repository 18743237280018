'use client'

import { useEffect, useState } from 'react'
import { Radio, RadioGroup } from '@headlessui/react'
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import { User } from '../../../../types/index'



type Course = {
  name: string;
  module: string;
  faculty: string;
  university: string;
  year_group: string;
  doc_count: number;
  store_size: number;
}

type CoursesProps = {
  selectedCourse: Course | null;
  onSelect: (selectedIndex: Course | null) => void;
}


export default function Courses(props: CoursesProps) {
  const [selected, setSelected] = useState<Course | null>(null);
  const [searchTerm, setSearchTerm] = useState('')
  const [courses, setCourses] = useState<Course[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const serverURI = process.env.REACT_APP_API_HOST;

  useEffect(() => {
    getInitData();
  }, []);

  useEffect(() => {
    if (!props.selectedCourse) {
      setSelected(null);
      setSearchTerm('');
    }
  }, [props.selectedCourse]);

  const handleSelectionChange = (course: Course | null) => {
    setSelected(course);
    props.onSelect(course);
  };

  // Filter plans based on search term
  const filteredPlans = courses.filter(plan =>
    plan.name.toLowerCase().includes(searchTerm.toLowerCase())
  )

  const getInitData = async (): Promise<void> => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(`${serverURI}/api/indexes`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const result = await response.json();
      
      setCourses(result);
    } catch (error: any) {
      console.error('Error fetching indexes:', error);
      setError(error.message || 'An unexpected error occurred.');
    } finally {
      setIsLoading(false);
    }
  };

  // New function to group courses by faculty
  const groupedCourses = filteredPlans.reduce((acc, course) => {
    if (!acc[course.faculty]) {
      acc[course.faculty] = [];
    }
    acc[course.faculty].push(course);
    return acc;
  }, {} as Record<string, Course[]>);

  // Update capitalize function to handle multiple words but not capitalize "en"
  const capitalize = (str: string) => {
    const excludedWords = ['en'];
    return str.split(' ').map(word => 
      excludedWords.includes(word.toLowerCase()) 
        ? word.toLowerCase()
        : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    ).join(' ');
  };

  return (
    <div className="flex flex-col h-full">
      <div className="w-full mb-4">
        <label htmlFor="search" className="sr-only">
          Search
        </label>
        <div className="relative text-white focus-within:text-gray-600">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            {isLoading ? (
              <div className="h-5 w-5 animate-spin rounded-full border-2 border-gray-500 qborder-t-transparent" />
            ) : (
              <MagnifyingGlassIcon aria-hidden="true" className="h-5 w-5 text-gray-500" />
            )}
          </div>
          <input
            id="search"
            name="search"
            type="search"
            placeholder={isLoading ? "Besig om kursusse te laai..." : "Vind 'n kursus"}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            disabled={isLoading}
            className="block w-full rounded-md border-2  bg-white/20 py-1.5 pl-10 pr-3 placeholder:text-gray-500 focus:bg-white focus:text-gray-900 focus:ring-0 focus:placeholder:text-gray-500 sm:text-sm sm:leading-6"
          />
        </div>
      </div>

      <fieldset aria-label="Server size" className="flex-1 overflow-y-auto scrollbar-hide">
        <RadioGroup 
          value={selected} 
          onChange={handleSelectionChange} 
          className="space-y-2"
        >
          {Object.keys(groupedCourses).map((faculty) => (
            <div key={faculty} className="relative">
              <div className="sticky top-0 z-10 border-y border-b-gray-200 border-t-gray-100 bg-gray-50 px-3 py-1.5 text-sm font-semibold text-gray-900">
                <h3>{capitalize(faculty)}</h3>
              </div>
              <div className="space-y-4 py-3">
                {groupedCourses[faculty].map((course) => (
                  <Radio
                    key={course.name}
                    value={course}
                    aria-label={course.name}
                    aria-description={`${course.name}, ${course.store_size}, ${course.doc_count} per month`}
                    className="group relative block cursor-pointer rounded-lg border border-gray-300 bg-white px-6 py-4 shadow-sm focus:outline-none data-[focus]:border-indigo-600 data-[focus]:ring-2 data-[focus]:ring-indigo-600 sm:flex sm:justify-between"
                  >
                    <span className="flex items-center">
                      <span className="flex flex-col text-sm">
                        <span className="font-medium text-gray-900">{capitalize(course.module)}</span>
                        <span className="text-gray-500">
                          <span className="block sm:inline">
                            {capitalize(course.faculty)} / {capitalize(course.university)} / {capitalize(course.year_group)}
                          </span>{' '}
                          
                        </span>
                      </span>
                    </span>
                    <span className="mt-2 flex text-sm sm:ml-4 sm:mt-0 sm:flex-col sm:text-right">
                      <span className="font-medium text-gray-900">{course.doc_count}</span>
                      <span className="ml-1 text-gray-500 sm:ml-0">dokumente</span>
                    </span>
                    <span
                      aria-hidden="true"
                      className="pointer-events-none absolute -inset-px rounded-lg border-2 border-transparent group-data-[focus]:border group-data-[checked]:border-indigo-600"
                    />
                  </Radio>
                ))}
              </div>
            </div>
          ))}
        </RadioGroup>
      </fieldset>

      {selected && (
        <div className="sticky bottom-0 pt-4">
          <button
            onClick={() => handleSelectionChange(null)}
            className="w-full rounded-md bg-red-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-brand-blue focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Herstel keuse
          </button>
        </div>
      )}
    </div>
  )
}
 