import React, { useState, useEffect, useRef } from 'react'
import { Chat } from './components/chat/chat'
import SearchInput from 'components/leerpleinki/components/search_input'
import { SearchResults } from 'components/leerpleinki/components/search_results'
import { Course } from '../../types'
import Courses from './components/courses/Courses_'
import { User } from '../../types'
import {
  actions,
  AppStatus,
  thunkActions,
  useAppDispatch,
  useAppSelector,
} from 'store/provider'
import RobotAvatar from './images/robot-avatar.png'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import ChatInput from './components/chat/input'
import { ChatMessageList } from './components/chat/message_list'

interface HomeProps {
  user: User;
  onBack: () => void;
}

const App: React.FC<HomeProps> = ({ user, onBack }) => {

    const dispatch = useAppDispatch()
    const status = useAppSelector((state) => state.status)
    const sources = useAppSelector((state) => state.sources)
    const messages = useAppSelector((state) => state.conversation)
    const [searchQuery, setSearchQuery] = useState<string>('')
    const [selectedCourse, setSelectedCourse] = useState<Course | null>(null)
    const [isLeftSidebarOpen, setIsLeftSidebarOpen] = useState(true)
    const [isRightSidebarOpen, setIsRightSidebarOpen] = useState(true)
    const messagesEndRef = useRef<HTMLDivElement>(null)
  
    useEffect(() => {
      setIsLeftSidebarOpen(true)
      setIsRightSidebarOpen(false)
    }, [])
  
    useEffect(() => {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
    }, [messages])
  
    const handleSearch = (query: string) => {
      if (status === AppStatus.Idle) {
        dispatch(actions.addMessage({
          id: Date.now().toString(),
          content: query,
          isHuman: true,
          sources: [],
        }));
        dispatch(thunkActions.search(query, selectedCourse?.name || null));
      } else {
        dispatch(thunkActions.askQuestion(query, selectedCourse?.name || null));
      }
    }
  
    const handleAbortRequest = () => {
      dispatch(thunkActions.abortRequest())
    }
  
    const handleToggleSource = (name: string) => {
      dispatch(actions.sourceToggle({ name }))
    }
  
    const handleSourceClick = (name: string) => {
      dispatch(actions.sourceToggle({ name, expanded: true }))
      setIsRightSidebarOpen(true)
      setTimeout(() => {
        document
          .querySelector(`[data-source="${name}"]`)
          ?.scrollIntoView({ behavior: 'smooth' })
      }, 300)
    }
  

    const handleCourseSelect = (course: Course | null) => {
        dispatch(actions.reset());
        setSelectedCourse(course);
        setIsLeftSidebarOpen(false);
      }

    const handleReset = () => {
      dispatch(actions.reset())
      setSelectedCourse(null)
      setIsLeftSidebarOpen(true)
    }

  return (
    <div className="flex h-screen">
      {/* Left Sidebar */}
      <div className={`fixed left-0 inset-y-0 z-50 flex flex-col border-r border-gray-200 bg-white transition-all duration-300 ${
        isLeftSidebarOpen ? 'w-full md:w-96 lg:w-[400px]' : 'w-0'
      }`}>
        {isLeftSidebarOpen && (
          <div className="flex h-full flex-col overflow-hidden">
            <div className="flex items-center justify-end p-2 border-b border-gray-200">
              <button
                onClick={() => setIsLeftSidebarOpen(false)}
                className="p-2 hover:bg-gray-100 rounded-md"
              >
                <ChevronLeftIcon className="h-5 w-5 text-gray-500" />
              </button>
            </div>
            <div className="flex-1 overflow-y-auto px-4 py-4">
              <button
                onClick={() => {
                  dispatch(actions.reset());
                  onBack();
                }}
                className="w-full mb-4 px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 hover:bg-gray-200 rounded-md"
              >
                Gaan terug
              </button>
              <div className="w-full">
                <Courses 
                  selectedCourse={selectedCourse}
                  onSelect={handleCourseSelect} 
                />
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Left Sidebar Toggle (visible when closed) */}
      {!isLeftSidebarOpen && (
        <button
          onClick={() => setIsLeftSidebarOpen(true)}
          className="fixed left-0 top-4 z-50 p-2 bg-white hover:bg-gray-100 rounded-r-md border border-l-0 border-gray-200"
        >
          <ChevronRightIcon className="h-5 w-5 text-gray-500" />
        </button>
      )}

      {/* Right Sidebar */}
      <div className={`fixed right-0 inset-y-0 z-50 flex flex-col border-l border-gray-200 bg-white transition-all duration-300 ${
        isRightSidebarOpen ? 'w-full md:w-[600px]' : 'w-0'
      }`}>
        {isRightSidebarOpen && (
          <div className="flex h-full flex-col overflow-hidden">
            <div className="flex items-center justify-start p-2 border-b border-gray-200">
              <button
                onClick={() => setIsRightSidebarOpen(false)}
                className="p-2 hover:bg-gray-100 rounded-md"
              >
                <ChevronRightIcon className="h-5 w-5 text-gray-500" />
              </button>
            </div>
            <div className="flex-1 overflow-y-auto px-6 py-4">
              <SearchResults 
                results={sources}
                toggleSource={handleToggleSource}
              />
            </div>
          </div>
        )}
      </div>

      {/* Right Sidebar Toggle (visible when closed) */}
      {!isRightSidebarOpen && (
        <button
          onClick={() => setIsRightSidebarOpen(true)}
          className="fixed right-0 top-4 z-50 p-2 bg-white hover:bg-gray-100 rounded-l-md border border-r-0 border-gray-200"
        >
          <ChevronLeftIcon className="h-5 w-5 text-gray-500" />
        </button>
      )}

      {/* Main chat area - Update margins */}
      <div className={`flex-1 flex flex-col h-screen transition-all duration-300 ${
        isLeftSidebarOpen ? 'ml-0 md:ml-96 lg:ml-[400px]' : 'ml-0'
      } ${
        isRightSidebarOpen ? 'mr-0 md:mr-[600px]' : 'mr-0'
      }`}>
        {status === AppStatus.Idle ? (
          <div className="flex flex-col h-full">
            <div className="flex-1 overflow-y-auto">
              <div className="flex items-center justify-center h-full">
                <div className="w-full max-w-3xl px-4">
                  <div className="text-center mb-8">
                    <img src={RobotAvatar} alt="Elastic Logo" className="w-16 h-16 mx-auto mb-4" />
                    <p className="text-gray-500">
                      {selectedCourse 
                        ? `Begin deur 'n vraag te vra oor ${selectedCourse.module}...` 
                        : "Kies 'n module om te begin"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-shrink-0 border-t border-gray-200 bg-white p-4">
              <div className="max-w-3xl mx-auto">
                <ChatInput
                  isMessageLoading={false}
                  onSubmit={handleSearch}
                  onAbortRequest={handleAbortRequest}
                  onReset={handleReset}
                  disabled={!selectedCourse}
                  placeholder={selectedCourse ? `${user.first_name}, wat wil jy graag weet?` : "Kies 'n kursus om voort te gaan"}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-col h-full">
            <div className="flex-1 overflow-y-auto">
              <div className="max-w-3xl mx-auto py-6 px-4">
                <ChatMessageList
                  user={user}
                  messages={messages}
                  isMessageLoading={status === AppStatus.StreamingMessage}
                  onSourceClick={handleSourceClick}
                  onOpenSidebar={() => setIsRightSidebarOpen(true)}
                />
                <div ref={messagesEndRef} />
              </div>
            </div>
            <div className="flex-shrink-0 border-t border-gray-200 bg-white p-4">
              <div className="max-w-3xl mx-auto">
                <ChatInput
                  isMessageLoading={status === AppStatus.StreamingMessage}
                  onSubmit={handleSearch}
                  onAbortRequest={handleAbortRequest}
                  onReset={handleReset}
                  disabled={!selectedCourse}
                  placeholder="Tik 'n boodskap..."
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default App;