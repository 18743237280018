import sosiaal from './images/sosiaal.jpg'
import leerplein from './images/lp.png'
import jobs from './images/werk.jpg'
export default function Home() {
    return (
      <div className="grid grid-cols-1 items-start gap-4 bg-brand-blue">
        <div className="grid grid-cols-1 gap-4">
          <section>
            <div className="overflow-hidden">
              <div className="mx-auto max-w-2xl lg:max-w-7xl">
                <h2 className="text-center text-base/7 font-semibold text-white">Jou tuiste</h2>
                <p className="mx-auto mt-2 max-w-lg text-balance text-center text-4xl font-semibold tracking-tight text-white sm:text-5xl">
                  Hulpmiddels vir 'n florerende studentelewe
                </p>
                <div className="mt-10 grid gap-4 sm:mt-16 lg:grid-cols-3 lg:grid-rows-2">
                  <div className="relative lg:row-span-2">
                    <div className="absolute inset-px rounded-lg bg-white lg:rounded-l-[2rem]"></div>
                    <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] lg:rounded-l-[calc(2rem+1px)]">
                      <div className="px-8 pb-3 pt-8 sm:px-10 sm:pb-0 sm:pt-10">
                        <p className="mt-2 text-lg font-medium tracking-tight text-gray-950 max-lg:text-center">
                          LeerPlein
                        </p>
                        <p className="mt-2 max-w-lg text-sm/6 text-gray-600 max-lg:text-center">
                          Studienotas, ou vraestelle en meer in Afrikaans.
                        </p>
                      </div>
                      <div className="relative min-h-[30rem] w-full grow [container-type:inline-size] max-lg:mx-auto max-lg:max-w-sm">
                        <div className="absolute inset-x-10 bottom-0 top-10 overflow-hidden rounded-t-[12cqw] border-x-[3cqw] border-t-[3cqw] border-gray-700 bg-gray-900 shadow-2xl">
                          <img
                            className="size-full object-cover object-top"
                            src={leerplein}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 lg:rounded-l-[2rem]"></div>
                  </div>
                  <div className="relative max-lg:row-start-1">
                    <div className="absolute inset-px rounded-lg bg-white max-lg:rounded-t-[2rem]"></div>
                    <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] max-lg:rounded-t-[calc(2rem+1px)]">
                      <div className="px-8 pt-8 sm:px-10 sm:pt-10">
                        <div className="mb-2 bg-red-500 rounded-lg p-2 text-white inline-block">Nuut</div>
                        <p className="mt-2 text-lg font-medium tracking-tight text-gray-950 max-lg:text-center">LeerPlein KI</p>
                        <p className="mt-2 max-w-lg text-sm/6 text-gray-600 max-lg:text-center">
                          Gebruik ons eie kunsmatige intelligensie platform om in Afrikaans te leer.
                        </p>
                      </div>
                       {/*<div className="flex flex-1 items-center justify-center px-8 max-lg:pb-12 max-lg:pt-10 sm:px-10 lg:pb-2">
                       <img
                          className="w-full max-lg:max-w-xs"
                          src="https://tailwindui.com/plus/img/component-images/bento-03-performance.png"
                          alt=""
                        /> 
                      </div>*/}
                    </div>
                    <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 max-lg:rounded-t-[2rem]"></div>
                  </div>
                  <div className="relative max-lg:row-start-3 lg:col-start-2 lg:row-start-2">
                    <div className="absolute inset-px rounded-lg bg-white"></div>
                    <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)]">
                      <div className="px-8 pt-8 sm:px-10 sm:pt-10">
                        <p className="mt-2 text-lg font-medium tracking-tight text-gray-950 max-lg:text-center">Sosiaal</p>
                        <p className="mt-2 max-w-lg text-sm/6 text-gray-600 max-lg:text-center">
                          Bespreek vroeg vir sosiale geleenthede en kry afslag.
                        </p>
                      </div>
                      <div className="flex flex-1 items-center [container-type:inline-size] max-lg:py-6 lg:pb-2">
                        <img
                          className="h-[min(152px,40cqw)] object-cover object-center rounded-r-[4cqw]"
                          src={sosiaal}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5"></div>
                  </div>
                  <div className="relative lg:row-span-2">
                    <div className="absolute inset-px rounded-lg bg-white max-lg:rounded-b-[2rem] lg:rounded-r-[2rem]"></div>
                    <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] max-lg:rounded-b-[calc(2rem+1px)] lg:rounded-r-[calc(2rem+1px)]">
                      <div className="px-8 pb-3 pt-8 sm:px-10 sm:pb-0 sm:pt-10">
                        <p className="mt-2 text-lg font-medium tracking-tight text-gray-950 max-lg:text-center">
                          Kry werk
                        </p>
                        <p className="mt-2 max-w-lg text-sm/6 text-gray-600 max-lg:text-center">
                          Ons help jou om voorkeurkandidate in ons netwerk van werkgewers te word.
                        </p>
                      </div>
                      <div className="relative min-h-[30rem] w-full grow">
                        <div className="absolute bottom-0 left-10 right-0 top-10 overflow-hidden rounded-tl-xl bg-brand-blue shadow-2xl">
                        
                          <div className="px-6 pb-0 pt-6">
                          <img
                          className=""
                          src={jobs}
                          alt=""
                        />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 max-lg:rounded-b-[2rem] lg:rounded-r-[2rem]"></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    )
  }
  