import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { GlobalStateProvider } from './store/provider'
import { User } from './types';
import { Auth0Provider } from '@auth0/auth0-react';
import { AuthProvider } from './contexts/AuthContext';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH_DOMAIN || ""}
      clientId={process.env.REACT_APP_AUTH_CLIENT_ID || ""}
      authorizationParams={{
        redirect_uri: process.env.REACT_APP_AUTH_REDIRECT_URL,
      }}
    >
      <AuthProvider>
        <GlobalStateProvider>
          <App/>
        </GlobalStateProvider>
      </AuthProvider>
    </Auth0Provider>
  </React.StrictMode>
)


