import React, { useState } from 'react'
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import logo from './assets/sp_logo.png'
import { User } from './types';
import { useAuth0 } from "@auth0/auth0-react";
import LoginButton from './components/LoginButton';
import LogoutButton from './LogoutButton';
import { useAuth } from 'contexts/AuthContext';
import Profile from './components/profile/profile';
import Loading from 'components/Loading'
import Home from './components/home/Home'
import LeerPleinKIHome from './components/leerpleinki/LeerPleinKIHome'

enum Page {
  PROFILE = 'profile',
  SETTINGS = 'settings',
  LEERPLEIN_KI = 'leerplein-ki',
  LEERPLEIN = 'leerplein',
  BESPREKINGS = 'besprekings',
  SOSIAAL = 'sosiaal',
  HOME = 'home',
}

const navigation = [
  { name: 'LeerPlein KI', page: Page.LEERPLEIN_KI, current: true },
  { name: 'LeerPlein', page: Page.LEERPLEIN, current: false },
  { name: 'Besprekings', page: Page.BESPREKINGS, current: false },
  { name: 'Sosiaal', page: Page.SOSIAAL, current: false },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

interface AppProps {
  user: User;
  onBack: () => void;
}

// Add this constant for page display names
const PAGE_DISPLAY_NAMES: Record<Page, string> = {
  [Page.PROFILE]: 'Profiel',
  [Page.SETTINGS]: 'Instellings',
  [Page.LEERPLEIN_KI]: 'LeerPlein KI',
  [Page.LEERPLEIN]: 'LeerPlein',
  [Page.BESPREKINGS]: 'Besprekings',
  [Page.SOSIAAL]: 'Sosiaal',
  [Page.HOME]: '',
};

// const dummyUserInfo: User = {
//   sub: 'auth0|123456789',
//   nickname: 'jannie',
//   name: 'Jannie Smith',
//   picture: 'https://i.pravatar.cc/150?u=jannie',
//   email: 'jannie.smith@student.example.com',
//   email_verified: true,
//   updated_at: '2024-03-20T10:00:00Z',
//   zoho_id: 'ZH123456',
//   first_name: 'Jannie',
//   last_name: 'Smith',
//   full_name: 'Jannie Smith',
//   phone: '+27 82 123 4567',
//   date_joined: '2023-01-15',
//   town: 'Stellenbosch',
//   student_number: '12345678',
//   year_of_study: '2',
//   university: 'Stellenbosch University',
//   course: 'BSc Computer Science',
//   faculty: 'Science',
//   residence: 'Helshoogte',
//   image_url: 'https://i.pravatar.cc/150?u=jannie',
//   instagram_handle: '@jannie_smith'
// };

export default function App() {
  const [currentPage, setCurrentPage] = useState<Page>(Page.HOME);
  const { accessToken, isLoading, error, userInfo, isLoadingUserInfo } = useAuth();

  if (isLoading) {
    return <Loading />;
  }

  const renderContent = () => {
    switch (currentPage) {
      case Page.PROFILE:
        return <Profile user={userInfo} onClose={() => setCurrentPage(Page.HOME)} />;
      case Page.HOME:
        return <Home />;
      default:
        return null;
    }
  };

  const handleNavigation = (e: React.MouseEvent, page: Page, close?: () => void) => {
    e.preventDefault();
    if (page === Page.LEERPLEIN) {
      window.open('https://leerplein.studenteplein.co.za', '_blank');
      if (close) close();
      return;
    }
    setCurrentPage(page);
    if (close) close();
  };

  const userNavigation = [
    { name: 'Jou profiel', page: Page.PROFILE, onClick: (e: React.MouseEvent) => handleNavigation(e, Page.PROFILE) },
    { name: 'Instellings', page: Page.SETTINGS, onClick: (e: React.MouseEvent) => handleNavigation(e, Page.SETTINGS) },
    { name: 'Teken uit', page: Page.HOME, href: '#' },
  ];

  if (currentPage === Page.LEERPLEIN_KI) {
    return <LeerPleinKIHome 
      user={userInfo!} 
      onBack={() => setCurrentPage(Page.HOME)} 
    />;
  }

  return (
    <>
      <div className="min-h-full">
        <div className="bg-brand-blue pb-32">
          <Disclosure as="nav" className="bg-white">
            {({ close }) => (
              <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
                <div className="border-b border-gray-700">
                  <div className="flex h-16 items-center justify-between px-4 sm:px-0">
                    <div className="flex items-center">
                      <div className="flex-shrink">
                        <img 
                          src={logo} 
                          alt="Company Logo" 
                          className="h-14 cursor-pointer" 
                          onClick={(e) => handleNavigation(e, Page.HOME)}
                        />
                      </div>
                      <div className="hidden md:block">
                        {accessToken && navigation.map((item) => (
                          <a
                            key={item.name}
                            href="#"
                            onClick={(e) => handleNavigation(e, item.page)}
                            aria-current={currentPage === item.page ? 'page' : undefined}
                            className={classNames(
                              currentPage === item.page
                                ? 'bg-gray-900 text-white'
                                : 'text-brand-blue hover:bg-gray-700 hover:text-white',
                              'rounded-md px-3 py-2 text-sm font-medium',
                            )}
                          >
                            {item.name}
                          </a>
                        ))}
                      </div>
                    </div>
                    <div className="hidden md:block">
                      <div className="ml-4 flex items-center md:ml-6">
                        <button
                          type="button"
                          className="relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                        >
                          <span className="absolute -inset-1.5" />
                          <span className="sr-only">View notifications</span>
                          <BellIcon aria-hidden="true" className="h-6 w-6" />
                        </button>

                     
                        {userInfo ? (
                          <Menu as="div" className="relative ml-3">
                            <div className="flex items-center gap-3">
                              <LogoutButton />
                              <MenuButton className="relative flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                <span className="absolute -inset-1.5" />
                                <span className="sr-only">Open user menu</span>
                                {userInfo?.picture && <img alt="" src={userInfo.picture} className="h-8 w-8 rounded-full" />}
                              </MenuButton>
                            </div>
                            <MenuItems
                              transition
                              className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                            >
                              {userNavigation.map((item) => (
                                <MenuItem key={item.name}>
                                  {({ close }) => (
                                    <a
                                      href="#"
                                      onClick={(e) => handleNavigation(e, item.page, close)}
                                      className="block px-4 py-2 text-sm text-brand-blue data-[focus]:bg-gray-100 data-[focus]:outline-none"
                                    >
                                      {item.name}
                                    </a>
                                  )}
                                </MenuItem>
                              ))}
                            </MenuItems>
                          </Menu>
                        ) : (
                          <div className="ml-3">
                            <LoginButton />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="-mr-2 flex md:hidden">
                      {/* Mobile menu button */}
                      <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                        <span className="absolute -inset-0.5" />
                        <span className="sr-only">Open main menu</span>
                        <Bars3Icon aria-hidden="true" className="block h-6 w-6 group-data-[open]:hidden" />
                        <XMarkIcon aria-hidden="true" className="hidden h-6 w-6 group-data-[open]:block" />
                      </DisclosureButton>
                    </div>
                  </div>
                </div>

                <DisclosurePanel className="border-b border-gray-700 md:hidden">
                  <div className="space-y-1 px-2 py-3 sm:px-3">
                    {userInfo ? (
                      navigation.map((item) => (
                        <DisclosureButton
                          key={item.name}
                          as="a"
                          href="#"
                          onClick={(e) => {
                            handleNavigation(e, item.page);
                            close();
                          }}
                          className="block rounded-md px-3 py-2 text-base font-medium text-brand-blue hover:bg-gray-700 hover:text-white"
                        >
                          {item.name}
                        </DisclosureButton>
                      ))
                    ) : (
                      <LoginButton />
                    )}
                  </div>
                  
                  {userInfo && (
                    <div className="border-t border-gray-700 pb-3 pt-4">
                      <div className="flex items-center px-5">
                        <div className="ml-3">
                          {userInfo && (
                            <>
                              <div className="text-base/5 font-medium text-white">{userInfo.name}</div>
                              <div className="text-sm font-medium text-gray-400">{userInfo.email}</div>
                            </>
                          )}
                        </div>
                        <div className="ml-auto flex items-center gap-3">
                          <LogoutButton />
                          <button
                            type="button"
                            className="relative shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                          >
                            <span className="absolute -inset-1.5" />
                            <span className="sr-only">View notifications</span>
                            <BellIcon aria-hidden="true" className="h-6 w-6" />
                          </button>
                        </div>
                      </div>
                      <div className="mt-3 space-y-1 px-2">
                        {userNavigation.map((item) => (
                          <DisclosureButton
                            key={item.name}
                            as="a"
                            href="#"
                            onClick={(e) => {
                              handleNavigation(e, item.page);
                              close();
                            }}
                            className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                          >
                            {item.name}
                          </DisclosureButton>
                        ))}
                      </div>
                    </div>
                  )}
                </DisclosurePanel>
              </div>
            )}
          </Disclosure>
          {userInfo && (
            <header className="py-10">
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <h1 className="text-3xl font-bold tracking-tight text-white">
                  {PAGE_DISPLAY_NAMES[currentPage]}
                </h1>
              </div>
            </header>
          )}
        </div>

        <main className="-mt-32">
          <div className={classNames(
            "mx-auto pb-12 px-4 sm:px-6 lg:px-8 max-w-7xl"
          )}>
            <div className={classNames(
              "rounded-lg shadow",
              currentPage === Page.HOME ? "bg-transparent shadow-none" : "bg-white px-5 py-6 sm:px-6"
            )}>
              {renderContent()}
            </div>
          </div>
        </main>
      </div>
    </>
  )
}
