import React, { useState, KeyboardEvent } from 'react'
import { ArrowUpIcon, StopIcon } from '@heroicons/react/24/outline'

interface ChatInputProps {
  isMessageLoading: boolean
  onSubmit: (message: string) => void
  onAbortRequest: () => void
  onReset?: () => void
  disabled?: boolean
  placeholder?: string
}

const ChatInput: React.FC<ChatInputProps> = ({
  isMessageLoading,
  onSubmit,
  onAbortRequest,
  onReset,
  disabled = false,
  placeholder = 'Type a message...',
}) => {
  const [message, setMessage] = useState('')

  const handleSubmit = () => {
    if (message.trim()) {
      onSubmit(message.trim())
      setMessage('')
    }
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      handleSubmit()
    }
  }

  return (
    <div className="relative flex items-center gap-2">
      <div className="relative flex-1">
        <textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          disabled={disabled}
          className="w-full resize-none rounded-xl border border-gray-300 bg-white px-4 py-3 pr-12 focus:border-brand-blue focus:outline-none focus:ring-0 disabled:bg-gray-100 disabled:text-gray-500"
          rows={1}
        />
        <button
          onClick={handleSubmit}
          disabled={!message.trim() || disabled}
          className="absolute right-3 top-1/2 -translate-y-1/2 rounded-lg border border-gray-300 p-1 text-gray-400 hover:text-gray-600 disabled:text-gray-300 disabled:border-gray-200"
        >
          <ArrowUpIcon className="h-6 w-6" />
        </button>
      </div>

      {isMessageLoading && (
        <button
          onClick={onAbortRequest}
          className="rounded-xl border border-gray-300 bg-white p-3 text-gray-400 hover:text-gray-600 h-[46px] flex items-center justify-center"
        >
          <StopIcon className="h-6 w-6" />
        </button>
      )}

      <button
        onClick={onReset}
        className="rounded-xl border border-gray-300 bg-white px-2 py-1 text-gray-600 hover:bg-gray-50 h-[46px] flex items-center justify-center"
      >
        Begin oor
      </button>
    </div>
  )
}

export default ChatInput
