import { SourceItem } from '../source_item'
import { SourceType } from 'types'

export type SourcesProps = {
  sources: SourceType[]
  showDisclaimer?: boolean
  onSourceClick: (source: string) => void
  onOpenSidebar: () => void
}

export const Sources: React.FC<SourcesProps> = ({
  sources,
  showDisclaimer,
  onSourceClick,
  onOpenSidebar,
}) => {
  return sources.length > 0 ? (
    <div className="mt-2">
      {showDisclaimer && (
        <h5 className="text-zinc-400 text-sm mb-2">Vanuit bronne:</h5>
      )}

      <div className="flex flex-wrap gap-2">
        {sources.map((source) => (
          <SourceItem
            key={source.name}
            name={source.name}
            icon={source.icon}
            onSourceClick={(name) => {
              console.log('Sources component click:', name)
              onSourceClick(name)
              onOpenSidebar()
            }}
          />
        ))}
      </div>
    </div>
  ) : null
}
