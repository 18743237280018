import { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { User } from '../types';
import Loading from 'components/Loading';

interface AuthContextType {
  accessToken: string | null;
  isLoading: boolean;
  isLoadingUserInfo: boolean;
  error: Error | null;
  userInfo: User | null;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }: { children: ReactNode }) {
  const { getAccessTokenSilently, isLoading: auth0Loading, isAuthenticated } = useAuth0();
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [userInfo, setUserInfo] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingUserInfo, setIsLoadingUserInfo] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const serverURI = process.env.REACT_APP_API_HOST;

  // Effect to get the token
  useEffect(() => {
    const getToken = async () => {
      try {
        const token = await getAccessTokenSilently();
        setAccessToken(token);
      } catch (err) {
        setError(err as Error);
      } finally {
        setIsLoading(false);
      }
    };

    if (!auth0Loading && isAuthenticated) {
      setIsLoading(true);
      getToken();
    }
  }, [getAccessTokenSilently, auth0Loading, isAuthenticated]);

  // Effect to verify token and get user info
  useEffect(() => {
    
    const verifyAndGetUserInfo = async () => {

      if (!accessToken) {
        setIsLoadingUserInfo(false);
        return;
      }
      setIsLoadingUserInfo(true);

      try {
        const userInfoResponse = await fetch('https://dev-w17s067u88vk6p5d.us.auth0.com/userinfo', {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          },
        });

        if (!userInfoResponse.ok) {
          throw new Error('Failed to fetch user info');
        }

        const userInfoData = await userInfoResponse.json();
        
       

        const userDetailsResponse = await fetch(`${serverURI}/api/user/get`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email: userInfoData.email }),
        });

        if (!userDetailsResponse.ok) {
          const errorData = await userDetailsResponse.json();
          throw new Error(errorData.message || 'Failed to fetch user details');
        }

        const userDetailsData = await userDetailsResponse.json();
        setUserInfo({ ...userInfoData, ...userDetailsData });
        console.log('User details:', userDetailsData);

      } catch (err) {
        console.error('Authentication error:', err);
        setError(err as Error);
        setAccessToken(null);
        setUserInfo(null);
      } finally {
        setIsLoadingUserInfo(false);
      }
  
    };

    verifyAndGetUserInfo();
  }, [accessToken, serverURI]);

  // Only show loading if we're authenticated and something is loading
  if (isAuthenticated && (auth0Loading || isLoadingUserInfo)) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <Loading />
      </div>
    );
  }

  return (
    <AuthContext.Provider value={{ 
      accessToken, 
      isLoading, 
      isLoadingUserInfo, 
      error, 
      userInfo 
    }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
} 