import React from 'react'
import { SourceIcon } from './source_icon'

export type SourceProps = {
  name: string
  icon: string
  onSourceClick: (sourceName: string) => void
}

export const SourceItem: React.FC<SourceProps> = ({
  name,
  icon,
  onSourceClick,
}) => (
  <button
    onClick={(e) => {
      e.preventDefault()
      e.stopPropagation()
      console.log('SourceItem clicked:', name)
      onSourceClick(name)
    }}
    className="hover:text-blue-600 hover:border-blue-500 inline-flex gap-2 items-center cursor-pointer px-2 py-1 border-2 rounded-md border-blue-300 text-white font-medium"
  >
    Bron:
    <span>{name}</span>
  </button>
)
