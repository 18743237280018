import React, { useEffect, useRef, useState } from 'react'
import { ChatMessage } from './message'
import { ChatMessageType, User } from 'types'

type ChatMessageListType = {
  messages: ChatMessageType[]
  isMessageLoading: boolean
  onSourceClick: (source: string) => void
  onOpenSidebar: () => void
  user: User
}
export const ChatMessageList: React.FC<ChatMessageListType> = ({
  messages,
  isMessageLoading,
  onSourceClick,
  onOpenSidebar,
  user,

}) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [userScrolled, setUserScrolled] = useState(false)

  useEffect(() => {
    const container = containerRef.current
    if (container && !userScrolled) {
      container.scrollTop = container.scrollHeight
    }
  }, [messages, userScrolled])

  const handleScroll = () => {
    const container = containerRef.current
    if (container) {
      const { scrollTop, clientHeight, scrollHeight } = container
      const isAtBottom = scrollTop + clientHeight >= scrollHeight - 10
      setUserScrolled(!isAtBottom)
    }
  }

  const handleSourceClick = (sourceName: string) => {
    console.log('ChatMessageList - handleSourceClick:', sourceName)
    onSourceClick(sourceName)
  }

  return (
    <div
      className="flex flex-col h-full overflow-y-auto"
      ref={containerRef}
      onScroll={handleScroll}
    >
      <div className="flex-1 min-h-0">
        {messages.map((message, index) => (
          <ChatMessage
            key={message.id || index}
            user={user}
            loading={
              messages.length - 1 === index &&
              !message.content.length &&
              isMessageLoading
              
            }
            onSourceClick={handleSourceClick}
            onOpenSidebar={onOpenSidebar}
            {...message}
          />
        ))}
      </div>
    </div>
  )
}
